import React, { Component } from 'react';
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import $ from "jquery"



class contact extends Component {
    constructor() {
        super();
        let url = window.location.href;
        let arr = url.split("/");
        let result = arr[0] + "//" + arr[2]
        this.state = {
          name: "",
          email: "",
          message: "",
          status: "Submit",
          error: "",
          response: "",
          seconds: 0,
          path: result
        };   

    this.onChange = this.onChange.bind(this);
    this.handleCaptchaResponseChange = this.handleCaptchaResponseChange.bind(this);
     this.onClickBtn = this.onClickBtn.bind(this)
    

    } 


    callback(){
      console.log("done");
      
    }

    verifyCallback(response){
      console.log(response);

    }

    expiredCallback() {
      console.log(`Recaptcha expired`);
    };
    

    onChange(event) {
      const state = this.state
      state[event.target.name] = event.target.value;
      this.setState(state);
    }

    handleCaptchaResponseChange(res) {
      console.log("Captcha value:", res)
      this.setState({
        response: res,
      });
    }

    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
   
    onClickBtn(event){
        $("#contact_container").addClass("animated slideOutDown");
        setTimeout(function(){
            $("#contact_container").removeClass("animated slideOutDown");
            $("#contact_container").css("display","none");
        },800);
    }

    handleSubmit(event) {
        event.preventDefault();
        console.log("Submitted)")
        console.log("message"+document.getElementById('message').value)
        const name = document.getElementById('name').value;
        const email = document.getElementById('email').value;
        const message = document.getElementById('message').value;
        const response = this.state.response;
        if(name === ""){
            this.setState({error: "Name was left blank."})
            return;
        }else if(email === ""){
          this.setState({error: "E-Mail was left blank"})
            return;
            
          
       }else if(this.validateEmail(email) === false){
        this.setState({error: "E-Mail is not valid"})
        return;

       }else if(message === ""){
        this.setState({error: "Message was left blank"})
            return;
            
      }else if(response === "" || response === null){
        this.setState({error: "The reCAPTCHA was invalid. Go back and try it again."})
            return;
            
      }


      axios.post(this.state.path + '/api/rcap',{'g-recaptcha-response' : response})
			  .then(res => {
         
			})
      
      axios({
        method: "POST", 
        url: this.state.path + "/send", 
        data: {
            name: name,   
            email: email,  
            message: message
        }
      }).then((response)=>{
        if (response.data.msg === 'success'){
            this.setState({error: "Message was sent successfully"})
              
            this.resetForm()
        }else if(response.data.msg === 'fail'){
            this.setState({error: "Message failed to send."})
        }
        setTimeout(function(){
          this.setState({error: ""})
        }.bind(this),3000);
    })
      
   
    }

    
    resetForm(){
      document.getElementById('contact-form').reset();
      this.recaptcha.reset();
      this.setState({response: ""})
      this.setState({seconds: 15}, () =>
      {
        this.timer = setInterval(this.countDown, 1000)

      })
    }

    render() {
		return (
		

    <div id="contact_container" className="container">
            <div id="ccontact" onClick={this.onClickBtn}><i id="ccontact" className="fa fa-angle-down"></i></div>
            <h1>Contact.</h1>
            <section>
                <h2>Contact Me</h2>
                
                    <form onSubmit={this.handleSubmit.bind(this)} method="POST">
                        <input type="text" id="name" placeholder="Name" required/>
                        <input type="email" id="email" placeholder="E-mail" required/><br/>
                        <textarea id="message" placeholder="Your message" required rows="5"></textarea><br/>
                        <ReCAPTCHA 
                            className=""
                            ref={(el) => { this.recaptcha = el; }}
                            sitekey="6Lc8sTYaAAAAAN7ttPS6jmXZ_J5ZUP_IY2YUB_VT" 
                            onChange={this.handleCaptchaResponseChange} 
                            verifyCallback={this.verifyCallback}
                            onloadCallback={this.callback}
                          />
                        <button className="btn_one">Send</button>
                    </form>
                    <div className="" id="error" role="alert">
                      {this.state.error}
                    </div> 
                
            </section>
        </div>
                
        

		);
	}
}

export default contact;