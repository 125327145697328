import React, { Component } from 'react';
import './App.css';
import './foster.css';
import 'animate.css'
import Contact from "../src/contact";
import data from '../src/files/projects.json'
import $ from 'jquery';





class App extends Component {
  constructor() {
    super();
    this.state = {
      pIndex : 0, //final value should be located at length-1
      list: data
    }
  this.onClickBtn = this.onClickBtn.bind(this)

  }

  componentDidMount(){
  setTimeout(function(){
    $("#loading").addClass("animated fadeOut");
    setTimeout(function(){
      $("#loading").removeClass("animated fadeOut");
      $("#loading").css("display","none");
      $("#box").css("display","none");
      $("#about").removeClass("animated fadeIn");
      $("#contact").removeClass("animated fadeIn");
      $("#work").removeClass("animated fadeIn");
    },1000);
  },1500);
}
  
  onClickBtn(event){
    switch(event.target.id){
      case "about":
        this.openabout();
        break;
      case "aboutm":
        this.openabout();
        break;
      case "cabout":
        this.closeabout();
        break;
      case "work":
        this.openwork();
        break;
      case "workm":
          this.openwork();
          break;
      case "cwork":
        this.closework();
        break;
      case "contact":
        this.opencontact();
        break;
      case "contactm":
          this.opencontact();
          break;
      case "ccontact":
        this.closecontact();
        break;
      case "prev":
        this.change(event.target.value)
        break;
      case "next":
        this.change(event.target.value)
        break;
    }
  }

  
  
  openabout(){
    $("#about_container").css("display","inherit");
    $("#about_container").addClass("animated slideInLeft");
    setTimeout(function(){
        $("#about_container").removeClass("animated slideInLeft");
    },800);
  }
  closeabout(){
    $("#about_container").addClass("animated slideOutLeft");
    setTimeout(function(){
        $("#about_container").removeClass("animated slideOutLeft");
        $("#about_container").css("display","none");
    },800);
  }
  openwork(){
    $("#work_container").css("display","inherit");
    $("#work_container").addClass("animated slideInRight");
    setTimeout(function(){
        $("#work_container").removeClass("animated slideInRight");
    },800);
  }
  closework(){
    $("#work_container").addClass("animated slideOutRight");
    setTimeout(function(){
        $("#work_container").removeClass("animated slideOutRight");
        $("#work_container").css("display","none");
    },800);
  }
  opencontact(){
    $("#contact_container").css("display","inherit");
    $("#contact_container").addClass("animated slideInUp");
    setTimeout(function(){
        $("#contact_container").removeClass("animated slideInUp");
    },800);
  }
  closecontact(){
    $("#contact_container").addClass("animated slideOutDown");
    setTimeout(function(){
        $("#contact_container").removeClass("animated slideOutDown");
        $("#contact_container").css("display","none");
    },800);
  }

  change(val){
    let newval = 0;
    let oldval = this.state.pIndex;
    if(val < 0){
      if(this.state.pIndex === 0){
        newval = this.state.list.length -1;
      
  
      } else {
        newval = this.state.pIndex - 1;
  
      }

        console.log("index: " + newval);
        $("#proj" + oldval).addClass("animated slideOutLeft");
        $("#proj" + oldval).css("display","none");
        
        setTimeout(function(){
          $("#proj" + oldval).removeClass("animated slideOutLeft");
        },800);
    
        $("#proj" + newval).css("display","inherit");
        $("#proj" + newval).addClass("animated slideInRight");
        setTimeout(function(){
          $("#proj" + newval).removeClass("animated slideInRight");
      },800);

    } else if(val > 0){
      if(this.state.pIndex === this.state.list.length - 1){
        newval = 0;
      
  
      } else {
        newval = this.state.pIndex  + 1;
  
      }
      $("#proj" + oldval).addClass("animated slideOutRight");
      $("#proj" + oldval).css("display","none");
      
      setTimeout(function(){
        $("#proj" + oldval).removeClass("animated slideOutRight");
      },800);
  
      $("#proj" + newval).css("display","inherit");
      $("#proj" + newval).addClass("animated slideInLeft");
      setTimeout(function(){
        $("#proj" + newval).removeClass("animated slideInLeft");
    },800);
    }


    this.setState({pIndex: newval})
  }

  next(val){

  }

  

  render(){
  return (
    <main>
      <head>   <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"/> </head>

    <div id="loading">
        <div id="spinner"></div>
    </div>
    <div id="box">
        <div className="box1 onlywide animated bounceOutLeft" style={{animationDelay:'1.7s'}}></div>
        <div className="box2 onlywide animated bounceOutLeft" style={{animationDelay:'1.8s'}}></div>
        <div className="box2 onlywide animated bounceOutLeft" style={{animationDelay:'1.9s'}}></div>
        <div className="box2 animated bounceOutRight" style={{animationDelay:'1.9s'}}></div>
        <div className="box2 onlywide animated bounceOutRight" style={{animationDelay:'1.8s'}}></div>
        <div className="box2 onlywide animated bounceOutRight" style={{animationDelay:'1.7s'}}></div>
    </div>
  
    <a id="about" onClick={this.onClickBtn} className="animate_fadeIn" style={{animationDelay:'2.2s'}}>About</a>
    <a id="work" onClick={this.onClickBtn} className="animated fadeIn" style={{animationDelay:'2.2s'}}>Projects</a>
    <a id="contact" onClick={this.onClickBtn} className="animated fadeIn" style={{animationDelay:'2.2s'}}>Contact</a>

    <div id="middle" className="animated slideInDown" style={{animationDelay:'2.0s'}}>
        <h1>Samuel Crumpler</h1>
        <h2>Software Developer / Maker / Nerd</h2>
        <div id="menu">
            <a id="aboutm" onClick={this.onClickBtn}>About</a>
            <a id="workm" onClick={this.onClickBtn}>Projects</a>
            <a id="contactm" onClick={this.onClickBtn}>Contact</a>
        </div>
     {// table of social media stuff goes here. May not even use
     
     }
    </div>

    <div id="work_container" className="container">
        <div id="cwork" onClick={this.onClickBtn}><i id="cwork" className="fa fa-angle-right"></i></div>
        <h1>Projects.</h1>

        {
          this.state.list.map((project, index) => {
            if(index === 0){
              return <section id={"proj"+index} key={index}>
                    <h2>{project.title}</h2>
                    <p>
                        {project.desc}
                    </p>
                    <div id="used">
                    {project.tags.map((tag,index) => {
                        return <div key={index}><i className="fa fa-circle"></i> {tag}</div>
                    })}
                    </div>
                    <div>
                      <a href={project.web} target="_blank" rel="noreferrer"><button className="btn_one" href={project.web}>View On Website</button></a>
                      <a href={project.git} target="_blank" rel="noreferrer"><button className={"btn_one "+project.disabled} >View On GitHub</button></a>

                    </div>
              
                  </section>

            } else{
              return <section id={"proj"+index} key={index} style={{display: "none"}}>
                    <h2>{project.title}</h2>
                    <p>
                        {project.desc}
                    </p>
                    <div id="used">
                    {project.tags.map((tag,index) => {
                        return <div key={index}><i className="fa fa-circle"></i> {tag}</div>
                    })}
                    </div>
                    <div>
                    <a href={project.web} target="_blank" rel="noreferrer"><button className="btn_one" href={project.web} >View On Website</button></a>
                      <a href={project.git} target="_blank" rel="noreferrer"><button className={"btn_one "+project.disabled} disabled={project.disabled}>View On GitHub</button></a>
                    </div>
              
                  </section>
            }
            
          })

        }

        <button id="prev" value={-1} className="btn_one" onClick={this.onClickBtn}>prev</button>
        <button id="next" value="1"className="btn_one" onClick={this.onClickBtn}>next</button>
    </div>

    <div id="about_container" className="container">
        <div id="cabout" onClick={this.onClickBtn}><i id="cabout" className="fa fa-angle-left"></i></div>
        <h1>About.</h1>
        <section>
            <h2>About me</h2>
            <p>
            I have always had an interest in computers from a young age. I started my ventures in information technology by troubleshooting computer problems as well as building and repairing them within a professional environment. During High School, I started getting invested in creating websites after a friend of mine acted as a tutor for me. This encouraged me to pursue a Computer Science degree in college, and ultimately lead to me earning my Bachelor’s Degree from Western Governors University.            </p>
            <p>
            As a full-stack developer, I am driven by working on new and exciting projects that involve using the newest technologies. My current focus is on MongoDB, Express.JS, React, Node.JS and React Native, though I have a good amount of experience using C# and Java for some smaller projects I work on in my spare time. Most of my experience is in web development, though during my college coursework I frequently worked with Java to develop several projects, which include an application for scheduling appointments, an android app for tracking class progress for college, and others. Even though I have a focus on a specific set of skills I have no issues adapting to new languages, and as such I’m open to learning anything that is thrown at me. I am fond of working in teams and do my absolute best to bring a positive working attitude to work every day.            </p>
        </section>
        <section>
            <h2>Skills</h2>
            <p>
            <strong>Languages:</strong> C#, C++, Java, JavaScript, CSS, HTML, LUA
            </p>
            <p>
            <strong>Frameworks & Libraries:</strong> WordPress, Node, Express, React, React Native, Mongoose, Bootstrap, Jest
            </p>
            <p>
            <strong>Databases:</strong> SQLite, SQL, MongoDB
            </p>
            <p>
            <strong>Other Technologies:</strong> GitHub, VS Code, Visual Studios, Android Studios, Unity, Unreal Engine
            </p>
        </section>
        <hr/>

        <section>
            <h2>Education</h2>
            <p>
            <strong>Western Governors University</strong> 
            <p>Bachelor’s Degreee in Software development</p>
            </p>
            <p>
            <strong>Modesto Junior College</strong> 
            <p>Associate’s Degreee in Computer Science</p>
            </p>
           
        </section>
        <hr/>

        <section>
            <h2>Certifications</h2>
            <p>
            <strong>CompTIA Project+</strong>
            <p>Project Management Certification</p>
            </p>
            <p>
            <strong>CIW Web Specialist</strong> 
            <p>Advanced HTML5 and CSS3 Specialist</p>
            </p>
            <p>
            <strong>CompTIA A+</strong> 
            <p>Technical Support and IT Operational Roles</p>
            </p>
        </section>
    </div>

   <Contact />
    
    </main>
    
  );
  }
}

export default App;
